import { captureRemixErrorBoundaryError, withSentry } from "@sentry/remix";
import type {
  ActionFunction,
  LoaderFunctionArgs,
} from "@remix-run/node";
import { json } from "@remix-run/node";
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useRouteError,
  useLoaderData,
  isRouteErrorResponse,
} from "@remix-run/react";

import "./styles/global.css";
import "./styles/global-small.css";
import "./styles/global-medium.css";
import "./styles/global-large.css";
import "react-toastify/dist/ReactToastify.css";
import { getEnv } from "./services/env.service.server";
import { CTA } from "./components/cta.component";
import { useEffect, useState } from "react";
import { getToast } from "remix-toast";
import { ToastContainer, toast as notify } from "react-toastify";
import { WebDIContainer } from "./di-containers/web.di-container.server";
import { FeatureFlag } from "./services/feature-flag.service.server";
import { getUser } from "./utils/session.server";
import ImpersonationBanner from "./components/impersonation-banner";
import { useNonce } from "./utils/nonce-provider";
import {
  TooltipContext,
  TooltipOverlay,
  type TooltipProps,
} from "./components/tooltip-overlay.component";
import * as Sentry from "@sentry/remix";

type Env = {
  ENV_NAME?: string;
  GTM_ID: string;
  HOT_JAR_ID: string;
  SENTRY_APP_DSN?: string;
  APP_VERSION?: string;
};

export async function loader({ request }: LoaderFunctionArgs) {
  const user = await getUser(request);
  const isImpersonating = !!user?.impersonated_by;

  const { toast, headers } = await getToast(request);

  const { featureFlagService } = await WebDIContainer();
  const allowRobots = featureFlagService.isEnabled(FeatureFlag.AllowRobots);

  Sentry.setUser(user);

  return json(
    {
      toast,
      ENV: {
        ENV_NAME: process.env.ENV_NAME ?? "development",
        LIVE_RELOAD: getEnv().LIVE_RELOAD,
        GTM_ID: getEnv().GTM_ID,
        HOT_JAR_ID: getEnv().HOT_JAR_ID,
        SENTRY_APP_DSN: process.env.SENTRY_APP_DSN,
        APP_VERSION: process.env.APP_VERSION,
      },
      allowRobots,
      isImpersonating,
      user: user
        ? {
            id: user.id,
            first_name: user.first_name,
            last_name: user.last_name,
            email: user.email,
          }
        : null,
    },
    { headers }
  );
}

export const action: ActionFunction = ({ request, context }) => {
  context.root_action = true;
  return null;
};

function Document({
  children,
  env,
  toast,
  allowRobots,
  isImpersonating,
  user,
  nonce,
}: {
  children: React.ReactNode;
  title?: string;
  env: Env;
  toast?:
    | {
        message: string;
        type: "info" | "success" | "warning" | "error";
      }
    | undefined;
  allowRobots?: boolean;
  isImpersonating?: boolean;
  user?: {
    id: string;
    first_name: string;
    last_name: string;
    email: string;
  } | null;
  nonce: string;
}) {
  const [tooltipProps, setTooltipProps] = useState<TooltipProps>({});

  useEffect(() => {
    if (toast) {
      // notify on a toast message
      notify(toast.message, { type: toast.type });
    }
  }, [toast]);

  useEffect(() => {
    (function (
      w: Window,
      d: Document,
      s: string,
      l: string,
      i: string,
      nonce: string
    ) {
      // @ts-ignore
      w[l] = w[l] || [];
      // @ts-ignore
      w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
      var f = d.getElementsByTagName(s)[0],
        j = d.createElement(s),
        dl = l != "dataLayer" ? "&l=" + l : "";
      // @ts-ignore
      j.async = true;
      // @ts-ignore
      j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
      j.setAttribute("nonce", nonce);
      if (
        f.parentElement!.querySelector(`script[src='${j.getAttribute("src")}']`)
      )
        return;
      // @ts-ignore
      // @ts-ignore
      f.parentNode.insertBefore(j, f);
    })(window, document, "script", "dataLayer", "GTM-5ZL6968D", nonce);
  }, [nonce]);

  useEffect(() => {
    // HotJar (https://help.hotjar.com/hc/en-us/articles/115009336727-How-to-Install-Your-Hotjar-Tracking-Code#manually-adding-the-code-to-your-website)
    if (env.HOT_JAR_ID) {
      (function (h: Window, o: Document, t: string, j: string) {
        // @ts-ignore
        h.hj =
          // @ts-ignore
          h.hj ||
          function () {
            // @ts-ignore
            (h.hj.q = h.hj.q || []).push(arguments);
          };
        // @ts-ignore
        h._hjSettings = { hjid: env.HOT_JAR_ID, hjsv: 6 };
        // @ts-ignore
        const src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
        // @ts-ignore
        const a = o.getElementsByTagName("head")[0];
        if (a.querySelector(`script[src='${src}']`)) return; // Prevents duplicate script tags
        const r = o.createElement("script");
        r.async = true;
        r.src = src;
        a.appendChild(r);
      })(window, document, "https://static.hotjar.com/c/hotjar-", ".js?sv=");
    }
  }, [env.HOT_JAR_ID]);

  return (
    <html lang="en" className="h-full bg-gray-50">
      <head>
        {/* Empty script needed for the GTM container to insert */}
        {env.GTM_ID && <script></script>}
        <meta
          name="viewport"
          content="viewport-fit=cover, width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no"
        />
        <link
          rel="icon"
          type="image/x-icon"
          href="/assets/favicon-64px.png"
        ></link>
        <meta charSet="utf-8" />
        {!allowRobots && <meta name="robots" content="noindex" />}
        <Meta />
        <Links />
        <script id="gtm-script" nonce={nonce} data-nonce={nonce}></script>
      </head>
      <body className="h-full">
        {env.GTM_ID && (
          <noscript>
            <iframe
              title="Google Tag Manager"
              src={`https://www.googletagmanager.com/ns.html?id=${env.GTM_ID}`}
              height="0"
              width="0"
              style={{ display: "none", visibility: "hidden" }}
            ></iframe>
          </noscript>
        )}
        <TooltipContext.Provider
          value={{
            ...tooltipProps,
            setTooltipProps,
          }}
        >
          <TooltipOverlay />
          {children}
        </TooltipContext.Provider>
        <script
          nonce={nonce}
          dangerouslySetInnerHTML={{
            __html: `window.ENV = ${JSON.stringify(env)}`,
          }}
        />
        <ScrollRestoration
          getKey={location => location.pathname} // Won't restore the scroll when only query params has changed
          nonce={nonce}
        />
        <Scripts nonce={nonce} />
        <ToastContainer closeOnClick hideProgressBar />
        {isImpersonating && <ImpersonationBanner user={user} />}
      </body>
    </html>
  );
}

function App() {
  const { ENV, toast, allowRobots, isImpersonating, user } =
    useLoaderData<typeof loader>();

  const nonce = useNonce();

  Sentry.setUser(user);

  return (
    <Document
      env={ENV}
      toast={toast}
      allowRobots={allowRobots}
      isImpersonating={isImpersonating}
      user={user}
      nonce={nonce}
    >
      <Outlet />
    </Document>
  );
}

export default withSentry(App);

export function ErrorBoundary() {
  const nonce = useNonce();
  const error = useRouteError();
  captureRemixErrorBoundaryError(error);
  if (isRouteErrorResponse(error)) {
    return (
      <Document
        title={`${error.status} ${error.statusText}`}
        env={{ GTM_ID: "", HOT_JAR_ID: "" }}
        nonce={nonce}
      >
        <div className="error-container w-full h-screen flex justify-center items-center flex-col">
          <h1 className="font-extrabold text-6xl my-2">
            {error.status} {error.statusText}
          </h1>
          {error.status == 403 && error.data && <pre>{error.data}</pre>}
          <CTA to="/" variant="coral-shadow" className="m-4 font-bold p-4">
            Go home
          </CTA>
        </div>
      </Document>
    );
  } else if (error instanceof Error) {
    return (
      <Document
        title="Uh-oh!"
        env={{ GTM_ID: "", HOT_JAR_ID: "" }}
        nonce={nonce}
      >
        <div className="error-container w-full h-screen flex justify-center items-center flex-col">
          <h1 className="font-extrabold text-6xl my-2">App Error</h1>
          <pre>{error.message}</pre>
          <CTA to="/" variant="coral-shadow" className="m-4 font-bold p-4">
            Go home
          </CTA>
        </div>
      </Document>
    );
  } else {
    <Document
      title="Uh-oh!"
      env={{ GTM_ID: "", HOT_JAR_ID: "" }}
      nonce={nonce}
    >
      <div className="error-container w-full h-screen flex justify-center items-center flex-col">
        <h1 className="font-extrabold text-6xl my-2">Unknown Error</h1>
        <CTA to="/" variant="coral-shadow" className="m-4 font-bold p-4">
          Go home
        </CTA>
      </div>
    </Document>;
  }
}
