/* eslint-disable jsx-a11y/anchor-is-valid */
import { Fragment, useEffect, useMemo, useState } from "react";
import { Dialog, Disclosure, Popover, Transition } from "@headlessui/react";
import {
  Bars3Icon,
  MagnifyingGlassIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { ArrowRightIcon, ChevronDownIcon } from "@heroicons/react/20/solid";
import {
  Form,
  Link,
  Outlet,
  useLoaderData,
  useLocation,
} from "@remix-run/react";
import type { LoaderFunctionArgs } from "@remix-run/node";
import { json } from "@remix-run/node";
import { getUser } from "~/utils/session.server";
import { Avatar } from "~/components/avatar.component";
import { Footer } from "~/components/footer.component";
import { WebDIContainer } from "~/di-containers/web.di-container.server";
import { ColumnMenu } from "~/components/column-menu.component";
import { CTA } from "~/components/cta.component";
import { RedirectLink } from "~/components/redirect-link.component";
import { ProductCategoryStatus } from "prisma-types";
import { AdminPermission } from "~/utils/admin-permission.utils";
import { userCanDo } from "~/utils/permissions.utils";

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

export const loader = async ({ request }: LoaderFunctionArgs) => {
  const user = await getUser(request);
  const { db, userService } = await WebDIContainer();
  const categories = await db.productCategory.findMany({
    where: {
      status: ProductCategoryStatus.ApprovedForPublishing,
    },
    orderBy: { name: "asc" },
  });
  const showAdminLink =
    user && userCanDo(user, AdminPermission.AccessAdminModule);

  const showVendorPortalLink =
    user && (await userService.userShouldSeeVendorPortalLink(user.id));

  return json({
    user,
    categories,
    showAdminLink,
    showVendorPortalLink,
  });
};

export default function MarketingRoute() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const { user, categories, showAdminLink, showVendorPortalLink } =
    useLoaderData<typeof loader>();
  const catsByType = categories.reduce((acc, cat) => {
    if (!acc[cat.primary_vendor_type]) {
      acc[cat.primary_vendor_type] = [];
    }
    acc[cat.primary_vendor_type].push(cat);
    return acc;
  }, {} as Record<string, typeof categories>);
  const location = useLocation();
  const [hasAcceptedCookies, setHasAcceptedCookies] = useState<
    boolean | undefined
  >(undefined);
  useEffect(() => {
    try {
      const hasAcceptedCookies =
        window.localStorage.getItem("has_accepted_cookies") === "true";
      setHasAcceptedCookies(hasAcceptedCookies);
    } catch (error) {
      console.error("Failed to access localStorage:", error);
      setHasAcceptedCookies(false); // Default behavior if localStorage is inaccessible
    }
  }, []);

  const loginLink = useMemo(() => {
    const redirectTo = location.search.includes("redirectTo");
    if (location.pathname === "/login") {
      return location.pathname + location.search;
    }
    if (location.pathname === "/sign-up") {
      return `/login${location.search}`;
    }
    return redirectTo
      ? `/login${location.search}`
      : `/login?redirectTo=${encodeURIComponent(
          location.pathname + location.search
        )}`;
  }, [location.pathname, location.search]);

  const signUpLink = useMemo(() => {
    const redirectTo = location.search.includes("redirectTo");
    if (location.pathname === "/sign-up") {
      return location.pathname + location.search;
    }
    if (location.pathname === "/login") {
      return `/sign-up${location.search}`;
    }
    return redirectTo
      ? `/sign-up${location.search}`
      : `/sign-up?redirectTo=${encodeURIComponent(
          location.pathname + location.search
        )}`;
  }, [location.pathname, location.search]);

  return (
    <div className="flex flex-col min-h-full">
      <div className="slider-container gap-x-4 py-2 lg:py-4 bg-amber-300 lg:flex justify-center items-center text-xs lg:text-sm">
        <p className="italic text-center lg:text-left">
          Revyse Announces Investment from RET Ventures, Reaches $2 Million in
          Venture Funding
        </p>
        <p className="flex justify-center">
          <Link
            className="flex items-center px-0 font-normal underline"
            to="/articles/revyse-reaches-2M-in-funding-with-investment-from-ret-ventures"
          >
            Learn more
            <ArrowRightIcon className="h-4 lg:ml-2" />
          </Link>
        </p>
      </div>
      <header className="bg-white">
        <div className="hidden md:flex justify-center border-b border-gray-100 py-2">
          <div className="text-right max-w-7xl flex-1 px-6 lg:px-8 text-sm">
            <RedirectLink
              id="intelligence-link"
              to="/intelligence"
              userLoggedIn={!!user}
              loginOrSignUp="login"
              className="mr-6"
            >
              Vendor Intelligence
            </RedirectLink>
            {showVendorPortalLink && (
              <RedirectLink
                id="vendor-portal-link"
                to="/vendor"
                userLoggedIn={!!user}
                loginOrSignUp="login"
                className="mr-6"
              >
                Vendor Portal
              </RedirectLink>
            )}
            <Link to="/for-vendors">For Vendors</Link>
          </div>
        </div>
        <nav
          className="mx-auto flex max-w-7xl items-center justify-between p-4 lg:px-8"
          aria-label="Global"
        >
          <div className="flex">
            <Link to="/" className="-m-1.5 p-1.5">
              <span className="sr-only">Revyse</span>
              <img
                className="h-8 w-auto"
                src="/assets/revyse-logo-color-black.png"
                alt=""
                width="320"
                height="112"
              />
            </Link>
          </div>

          <div className="flex items-center">
            {location.pathname !== "/" && (
              <Form
                method="get"
                action="/search"
                className="md:mr-12 mx-2 relative flex-shrink"
              >
                <input
                  type="text"
                  name="query"
                  className="rounded-full p-1 pl-8 focus:border-sky-600 focus:ring-sky-600"
                  placeholder="Search Revyse"
                />
                <MagnifyingGlassIcon className="h-5 top-2 left-2 absolute" />
              </Form>
            )}
            <Popover.Group className="hidden lg:flex lg:gap-x-12">
              <Popover className="relative">
                <Popover.Button className="flex items-center gap-x-1 text-sm font-semibold leading-6 text-gray-900">
                  Explore
                  <ChevronDownIcon
                    className="h-5 w-5 flex-none text-gray-400"
                    aria-hidden="true"
                  />
                </Popover.Button>

                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-200"
                  enterFrom="opacity-0 translate-y-1"
                  enterTo="opacity-100 translate-y-0"
                  leave="transition ease-in duration-150"
                  leaveFrom="opacity-100 translate-y-0"
                  leaveTo="opacity-0 translate-y-1"
                >
                  <Popover.Panel className="absolute -left-8 top-full z-20 mt-5 w-screen max-w-3xl overflow-hidden rounded-b bg-white shadow-lg">
                    <ColumnMenu
                      items={Object.keys(catsByType).map(key => ({
                        label: key,
                        items: catsByType[key].map(cat => ({
                          label: cat.name,
                          to: `/categories/${cat.slug}`,
                        })),
                      }))}
                    />
                  </Popover.Panel>
                </Transition>
              </Popover>

              <a
                href="/rvi"
                className="text-sm font-semibold leading-6 text-gray-900"
              >
                Buy + Manage
              </a>
              <div
                onClick={() => {
                  window.open("https://community.revyse.com", "_blank");
                }}
                className="text-sm font-semibold leading-6 text-gray-900 cursor-pointer"
              >
                Community
              </div>
              <Link
                id="resources-link"
                to="/articles"
                className="text-sm font-semibold leading-6 text-gray-900"
              >
                Resources
              </Link>
              {showAdminLink && (
                <Link
                  id="admin-link"
                  to="/admin"
                  className="text-sm font-semibold leading-6 text-coral"
                >
                  Admin
                </Link>
              )}
            </Popover.Group>
          </div>
          <div className="hidden lg:flex lg:justify-end lg:items-center text-gray-900">
            {!user ? (
              <>
                <Link
                  to={loginLink}
                  className="text-sm font-semibold leading-6 rounded-2xl border border-coral text-coral px-4 py-1 mr-2"
                >
                  Log In
                </Link>
                <Link
                  to={signUpLink}
                  className="text-sm font-semibold leading-6 rounded-2xl bg-coral text-white px-4 py-1"
                >
                  Sign Up
                </Link>
              </>
            ) : (
              <>
                <Link to="/logout" className="h-6 mr-1">
                  Logout
                </Link>
                <Link to="/profile" id="profile-link">
                  <Avatar
                    first_name={user.first_name!}
                    last_name={user.last_name!}
                    img_id={user.avatar_id}
                    className="h-8 w-8 text-sm"
                  />
                </Link>
              </>
            )}
          </div>
          <div className="flex lg:hidden">
            <button
              type="button"
              className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(true)}
            >
              <span className="sr-only">Open main menu</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
        </nav>
        <Dialog
          as="div"
          className="lg:hidden"
          open={mobileMenuOpen}
          onClose={setMobileMenuOpen}
        >
          <div className="fixed inset-0 z-10" />
          <Dialog.Panel className="fixed inset-y-0 right-0 z-20 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
            <div className="flex items-center justify-between">
              <a href="#" className="-m-1.5 p-1.5">
                <span className="sr-only">Revyse</span>
                <img
                  className="h-8 w-auto"
                  src="assets/revyse-logo-color-black.png"
                  alt=""
                  width="320"
                  height="112"
                />
              </a>
              <button
                type="button"
                className="-m-2.5 rounded-md p-2.5 text-gray-700"
                onClick={() => setMobileMenuOpen(false)}
              >
                <span className="sr-only">Close menu</span>
                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <div className="mt-6 flow-root">
              <div className="-my-6 divide-y divide-gray-500/10">
                <div className="space-y-2 py-6">
                  <Disclosure as="div" className="-mx-3">
                    {({ open }) => (
                      <>
                        <Disclosure.Button className="flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 hover:bg-gray-50">
                          Explore
                          <ChevronDownIcon
                            className={classNames(
                              open ? "rotate-180" : "",
                              "h-5 w-5 flex-none"
                            )}
                            aria-hidden="true"
                          />
                        </Disclosure.Button>
                        <Disclosure.Panel className="mt-2 space-y-2">
                          {Object.keys(catsByType).map(key => {
                            const item = catsByType[key];
                            return (
                              <Disclosure key={key} as="div" className="-mx-3">
                                {({ open }) => (
                                  <>
                                    <Disclosure.Button className="flex w-full items-center justify-between rounded-lg py-2 pl-8 pr-3.5 text-base font-semibold leading-7 hover:bg-gray-50">
                                      {key}
                                      <ChevronDownIcon
                                        className={classNames(
                                          open ? "rotate-180" : "",
                                          "h-5 w-5 flex-none"
                                        )}
                                        aria-hidden="true"
                                      />
                                    </Disclosure.Button>
                                    <Disclosure.Panel className="mt-2 space-y-2">
                                      {item.map((cat, i) => {
                                        return i < 10 ? (
                                          <Disclosure.Button
                                            key={cat.id}
                                            as="a"
                                            href={`/categories/${cat.slug}`}
                                            className="block rounded-lg py-2 pl-10 pr-3 text-sm font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                                          >
                                            {cat.name}
                                          </Disclosure.Button>
                                        ) : i === 10 ? (
                                          <Disclosure.Button
                                            key={cat.id}
                                            as="a"
                                            href={`/categories`}
                                            className="flex items-center rounded-lg py-2 pl-10 pr-3 text-sm font-semibold leading-7 text-sky-600 hover:bg-gray-50"
                                          >
                                            See All{" "}
                                            <ArrowRightIcon className="h-4 ml-4" />
                                          </Disclosure.Button>
                                        ) : (
                                          <></>
                                        );
                                      })}
                                    </Disclosure.Panel>
                                  </>
                                )}
                              </Disclosure>
                            );
                          })}
                        </Disclosure.Panel>
                      </>
                    )}
                  </Disclosure>

                  <Link
                    to="/rvi"
                    onClick={() => setMobileMenuOpen(false)}
                    className="-mx-3 block rounded-lg py-2 px-3 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  >
                    Buy + Manage
                  </Link>
                  <div
                    onClick={() => {
                      window.open("https://community.revyse.com", "_blank");
                    }}
                    className="-mx-3 block rounded-lg py-2 px-3 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  >
                    Community
                  </div>
                  <Link
                    id="resources-link"
                    to="/articles"
                    onClick={() => setMobileMenuOpen(false)}
                    className="-mx-3 block rounded-lg py-2 px-3 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  >
                    Resources
                  </Link>
                  <RedirectLink
                    to="/intelligence"
                    loginOrSignUp="login"
                    userLoggedIn={!!user}
                    className="-mx-3 block rounded-lg py-2 px-3 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  >
                    Vendor Intelligence
                  </RedirectLink>
                  {showVendorPortalLink && (
                    <RedirectLink
                      to="/vendor"
                      userLoggedIn={!!user}
                      loginOrSignUp="login"
                      className="-mx-3 block rounded-lg py-2 px-3 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                    >
                      Vendor Portal
                    </RedirectLink>
                  )}
                  <Link
                    to="/for-vendors"
                    className="-mx-3 block rounded-lg py-2 px-3 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  >
                    For Vendors
                  </Link>
                </div>
                <div className="py-6">
                  {!user ? (
                    <Link
                      to={loginLink}
                      onClick={() => setMobileMenuOpen(false)}
                      className="-mx-3 block rounded-lg py-2.5 px-3 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                    >
                      Log in
                    </Link>
                  ) : (
                    <Link
                      to="/logout"
                      onClick={() => setMobileMenuOpen(false)}
                      className="-mx-3 block rounded-lg py-2.5 px-3 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                    >
                      Log out
                    </Link>
                  )}
                </div>
              </div>
            </div>
          </Dialog.Panel>
        </Dialog>
      </header>
      <div className="flex-grow overflow-x-hidden">
        <Outlet />
      </div>
      <Footer />

      {hasAcceptedCookies !== undefined && !hasAcceptedCookies && (
        <div className="w-full fixed bottom-0 right-0 z-50 p-6 bg-sky-50 shadow border-t border-gray-200 flex gap-4">
          <p className="flex-grow m-0">
            <div className="flex items-center space-x-3">
              <img
                className="w-8 h-8"
                src={`/assets/cookies.webp`}
                alt="Cookies"
                width="523"
                height="450"
              />
              <div>
                We use cookies to deliver the best possible website experience.
                Check out our{" "}
                <Link to="/privacy-policy" className="text-sky-600">
                  privacy policy
                </Link>{" "}
                to learn more. By using our website, you accept our use of
                cookies.
              </div>
            </div>
          </p>
          <CTA
            onClick={() => {
              try {
                window.localStorage.setItem("has_accepted_cookies", "true");
                setHasAcceptedCookies(true);
              } catch (error) {
                console.error("Failed to access localStorage:", error);
              }
            }}
            className="w-48"
          >
            Ok
          </CTA>
        </div>
      )}
    </div>
  );
}
