import { useNavigate } from "@remix-run/react";
import React from "react";
import { tv } from "tailwind-variants";

export type ButtonProps = {
  to?: string;
  children: React.ReactNode;
  type?: "submit" | "button" | "div";
  className?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  disabled?: boolean;
  color?: "sky" | "danger" | "transparent" | "cancel" | undefined;
  id?: string;
  noPadding?: boolean;
};

const tvButton = tv({
  base: "flex items-center justify-center rounded-md px-3 py-2 text-sm font-semibold focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2",
  variants: {
    color: {
      sky: "bg-sky-600 hover:bg-sky-500 focus-visible:outline-sky-600 text-white shadow-sm",
      danger:
        "bg-red-600 hover:bg-red-500 focus-visible:outline-red-600 text-white shadow-sm",
      transparent: "text-sky-500 hover:text-sky-600",
      cancel: "text-gray-400 hover:text-gray-500",
    },
    disabled: {
      true: "opacity-30 pointer-events-none",
    },
    noPadding: {
      true: "px-0 py-0"
    }
  },
  defaultVariants: {
    color: "sky",
  },
});

export function Button({
  to,
  type,
  children,
  className,
  onClick,
  disabled = false,
  color,
  id,
  noPadding = false,
}: ButtonProps) {
  const navigate = useNavigate();
  type = type ?? "button";
  onClick =
    onClick ??
    (to
      ? e => {
          e.preventDefault();
          e.stopPropagation();
          navigate(to!);
        }
      : () => {});
  return type !== "div" ? (
    <button
      onClick={onClick}
      type={type}
      className={tvButton({ className, color, disabled, noPadding })}
      disabled={disabled}
      id={id}
    >
      {children}
    </button>
  ) : (
    <div
      onClick={!disabled && (onClick as any)}
      className={tvButton({ className, color, disabled, noPadding })}
      id={id}
    >
      {children}
    </div>
  );
}

export function DangerButton({
  type,
  children,
  id,
  className,
  onClick,
}: {
  children: JSX.Element | string;
  id?: string;
  type?: "submit" | "button";
  className?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
}) {
  return (
    <Button
      type={type}
      id={id}
      onClick={onClick}
      className={className}
      color="danger"
    >
      {children}
    </Button>
  );
}
